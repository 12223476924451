import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["accounttype", "interestrate", "creditlimit", "currencies", "accountnumber"];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    if (this.accounttypeTarget.value == "credit") {
      this.interestrateTarget.classList.remove("is-hidden");
      this.creditlimitTarget.classList.remove("is-hidden");
      this.accountnumberTarget.classList.add("is-hidden");
    } else if (this.accounttypeTarget.value == "savings") {
      this.interestrateTarget.classList.remove("is-hidden");
      this.creditlimitTarget.classList.add("is-hidden");
      this.accountnumberTarget.classList.remove("is-hidden");
    } else {
      this.interestrateTarget.classList.add("is-hidden");
      this.creditlimitTarget.classList.add("is-hidden");
      this.accountnumberTarget.classList.remove("is-hidden");
    }
  }

  addCurrencyField(event) {
    event.preventDefault();

    // Find the last currency field's index
    let lastField = this.currenciesTarget.querySelector(
      ".account_balance_fields:last-child"
    );
    let lastIndex = parseInt(
      lastField.querySelector("input, select").name.match(/\[(\d+)\]/)[1]
    );
    let newIndex = lastIndex + 1;

    // Clone the last currency field
    let newField = lastField.cloneNode(true);

    // Get all selected currencies
    let selectedCurrencies = Array.from(
      this.currenciesTarget.querySelectorAll("select")
    ).map((select) => select.value);

    // Update the name and id attributes of the clone to have the new index
    newField.querySelectorAll("input, select").forEach((input) => {
      input.name = input.name.replace(/\[\d+\]/, `[${newIndex}]`);
      if (input.id) {
        let newId = input.id.replace(/_\d+_/, `_${newIndex}_`);
        input.id = newId;

        // Update label if it exists
        if (input.labels && input.labels.length > 0) {
          input.labels[0].setAttribute("for", newId);
        }
      }

      // Reset and enable input values for the new field
      if (input.type !== "hidden") {
        if (input.type === "checkbox" || input.type === "radio") {
          input.checked = false;
        } else if (input.tagName.toLowerCase() === "select") {
          // Remove already selected currencies from the options
          Array.from(input.options).forEach((option) => {
            if (selectedCurrencies.includes(option.value)) {
              option.remove();
            }
          });
          input.value = "";
        } else {
          input.value = "0.00";
        }
        input.disabled = false;
      }
    });

    // Unhide the element with id currency-field-actions
    newField
      .querySelector("#currency-field-actions")
      .classList.remove("is-hidden");

    // Append the new field to the currencies container
    this.currenciesTarget.appendChild(newField);
  }

  removeCurrencyField(event) {
    event.preventDefault();
    if (this.currenciesTarget.querySelectorAll(".account_balance_fields").length >  1) {
      event.target.closest(".account_balance_fields").remove();
    }
  }
}
