import { Controller } from "stimulus";
import i18n from "../config/i18n";

export default class extends Controller {
  static targets = ["modal"];

  view(event) {
    event.preventDefault();
    const emailId = event.currentTarget.dataset.emailId;
    // Assuming you have a modal in your HTML with an ID of 'emailModal'
    const modal = document.getElementById("emailModal");
    // Optionally load email content dynamically and then display it
    this.loadEmailContent(emailId, modal);
  }

  loadEmailContent(emailId, modal) {
    const iframe = modal.querySelector("iframe");
    iframe.style.pointerEvents = 'none'; // Disable iframe interactions

    fetch(`/emails/${emailId}`)
      .then((response) => response.text())
      .then((html) => {
        const styledHtml = `
          <!DOCTYPE html>
          <html>
            <head>
              <style>
                body {
                  /* Font stack for cross-platform compatibility */
                  font-family: 
                    -apple-system,     /* iOS/macOS */
                    BlinkMacSystemFont,/* Chrome OS/macOS */
                    'Segoe UI',        /* Windows */
                    Roboto,            /* Android/Chrome */
                    'Helvetica Neue',  /* All systems */
                    Arial,             /* All systems */
                    sans-serif;        /* Generic fallback */
                  margin: 0;
                  padding: 0px;
                  line-height: 1.5;
                  max-width: 100%;
                  box-sizing: border-box;
                }

                blockquote {
                  margin-inline-start: 10px;
                  margin-inline-end: 10px;
                }

                img, table, tbody, tr, td, div, p, h1, h2, h3, h4, h5, h6, ul, ol, li, blockquote, pre, code, span, a {
                  max-width: 100% !important;
                  height: auto !important;
                }
              </style>
            </head>
            <body>
              ${html}
            </body>
          </html>
        `;

        iframe.srcdoc = styledHtml;
        modal.classList.add("is-active");

        iframe.onload = () => {
          iframe.style.height = `${iframe.contentDocument.body.scrollHeight}px`;
          iframe.style.width = '100%';
        };
      })
      .catch((error) => {
        console.error("Error loading email content:", error);
      });
  }

  closeModal() {
    const modal = document.getElementById("emailModal");

    modal.classList.remove("is-active");
  }

  delete(event) {
    event.preventDefault();
    const emailId = event.currentTarget.dataset.emailId;
    const emailRows = document.querySelectorAll(
      `tr[data-email-id="${emailId}"]`
    );

    if (confirm(i18n.t("messages.delete_email"))) {
      // Perform the fetch request to delete the email
      fetch(`/emails/${emailId}`, {
        method: "DELETE",
        headers: {
          "X-CSRF-Token": this.getMetaValue("csrf-token"), // Ensure CSRF token is included for Rails
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.ok) {
          emailRows.forEach((row) => row.remove());
        }
      });
    }
  }

  // Helper method to get meta values (e.g., CSRF token)
  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
