// Core libraries
require("@rails/ujs").start();
require("@rails/actioncable");
require("@rails/activestorage").start();
require("channels");
require.context("../application/images", true);

// Font Awesome and Stylesheets
import "@fortawesome/fontawesome-free/js/all";
import "../application/stylesheets/application";

import "animate.css";
import "config";
import "controllers";
import "channels";

// Bulma TagsInput
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";

BulmaTagsInput.attach('select[data-type="tags"]', {
  caseSensitive: false,
  tagClass: "tag is-info",
});
